/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.league-spartan {
	font-family: "League Spartan", sans-serif;
	font-optical-sizing: auto;
	font-style: normal;
}

@keyframes gradientShift {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}

.bg-pink-gradient {
	/* Define your gradient here */
	background: linear-gradient(270deg, #ffbace, rgb(165, 132, 149));
	background-size: 200% 200%;
	/* Apply the animation */
	animation: gradientShift 6s ease infinite;
}

.app-container {
	position: relative;
	/* Ensure the container allows positioning */
	height: 100vh;
	/* Full viewport height */
	width: 100vw;
	/* Full viewport width */
	overflow: hidden;
	/* Hide overflow to avoid scrollbars */
	background-color: #f0f8ff;
	/* Example background color */
	display: flex;
	justify-content: center;
	align-items: center;
}

.relative {
	position: relative;
}

.mx-4 {
	margin-left: 1rem;
	margin-right: 1rem;
}

.text-center {
	text-align: center;
}

@keyframes shake {

	0%,
	100% {
		transform: rotate(0deg);
	}

	25% {
		transform: rotate(-15deg);
	}

	50% {
		transform: rotate(15deg);
	}

	75% {
		transform: rotate(-15deg);
	}
}

.flower:hover {
	animation: shake 0.5s ease-in-out;
}